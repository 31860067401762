import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link, animateScroll } from "react-scroll";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import logo from "../../assets/images/ES-TAGLINE-red-high-res.png";
import { LANGUAGE, ROUTES, SCROLL_DURATION } from "../../utils";
import { useEffect } from "react";
import "./styles.css";

const AppHeader = () => {
  const { i18n, t } = useTranslation();
  const { pathname, hash } = useLocation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const isMenuLangActive = (lng) => {
    return lng === i18next.language ? "active" : "";
  };

  const isMenuActive = () => {
    return pathname === ROUTES.HOMEPAGE ? "active" : "";
  };

  const getMenuLink = (key, to, offset = 0) => {
    if (pathname === ROUTES.HOMEPAGE) {
      return (
        <Link
          to={to}
          smooth
          duration={SCROLL_DURATION}
          offset={offset}
          className="nav-link cursor-pointer"
        >
          {t(key)}
        </Link>
      );
    }

    return (
      <LinkRouter className="nav-link cursor-pointer" to={ROUTES.HOMEPAGE}>
        {t(key)}
      </LinkRouter>
    );
  };

  useEffect(() => {
    if (hash.length > 0) {
      setTimeout(() => {
        animateScroll.scrollTo(document.querySelector(hash).offsetHeight, {
          duration: SCROLL_DURATION,
        });
      }, 2000);
    }
  }, [hash]);

  return (
    <header className="header_area" id="section-header">
      <div className="main_menu">
        {/* <div className="search_input" id="search_input_box">
          <div className="container">
            <form className="d-flex justify-content-between">
              <input
                type="text"
                className="form-control"
                id="search_input"
                placeholder="Search Here"
              />
              <button type="submit" className="btn"></button>
              <span
                className="lnr lnr-cross"
                id="close_search"
                title="Close Search"
              ></span>
            </form>
          </div>
        </div> */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <LinkRouter className="navbar-brand logo_h" to={ROUTES.HOMEPAGE}>
              <img src={logo} alt="Logo ES Datadvisory" />
            </LinkRouter>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className="collapse navbar-collapse offset"
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav menu_nav ml-auto">
                <li className={`nav-item ${isMenuActive()}`}>
                  {getMenuLink("appHeader.home", "section-header")}
                </li>
                <li className="nav-item">
                  {getMenuLink("appHeader.about", "about", -150)}
                </li>
                <li className="nav-item">
                  {getMenuLink("appHeader.services", "services", -150)}
                </li>
                <li className="nav-item">
                  {getMenuLink("appHeader.team", "team", -150)}
                </li>
                {/* <li className="nav-item">
                  {getMenuLink("appHeader.publications", "publication", -150)}
                </li> */}
                <li className="nav-item">
                  {getMenuLink("appHeader.contact", "contact", -150)}
                </li>
                <li className="nav-item submenu dropdown">
                  <a
                    className="nav-link dropdown-toggle cursor-pointer"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t("appHeader.language")}
                  </a>
                  <ul className="dropdown-menu">
                    <li className={`nav-item ${isMenuLangActive(LANGUAGE.EN)}`}>
                      <a
                        className="nav-link cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          changeLanguage(LANGUAGE.EN);
                        }}
                      >
                        English
                      </a>
                    </li>
                    <li className={`nav-item ${isMenuLangActive(LANGUAGE.FR)}`}>
                      <a
                        className="nav-link cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          changeLanguage(LANGUAGE.FR);
                        }}
                      >
                        Français
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* <ul className="nav navbar-nav ml-auto">
                <div className="social-icons d-flex align-items-center">
                  <a href="">
                    <li>
                      <i className="fa fa-facebook"></i>
                    </li>
                  </a>
                  <a href="">
                    <li>
                      <i className="fa fa-twitter"></i>
                    </li>
                  </a>
                  <a href="">
                    <li>
                      <i className="fa fa-dribbble"></i>
                    </li>
                  </a>
                  <a href="">
                    <li>
                      <i className="fa fa-behance"></i>
                    </li>
                  </a>
                </div>
                <li className="nav-item">
                  <a href="#" className="search">
                    <i className="lnr lnr-magnifier" id="search"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default AppHeader;
