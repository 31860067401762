export const SCROLL_DURATION = 500;

export const LANGUAGE = {
  EN: "en",
  FR: "fr",
};

export const ROUTES = {
  HOMEPAGE: "/",
  LEGAL: "/legal",
  POLICY: "/policy",
};
