import { useTranslation, Trans } from "react-i18next";
import "./styles.css";

const VideoArea = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="video-sec-area section_gap_top" id="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 video-left overlay">
              <div className="video-inner justify-content-center align-items-center d-flex"></div>
            </div>
            <div className="col-lg-6 video-right">
              <h1>{t("videoArea.title")}</h1>
              <p className="text-justify">{t("videoArea.dataProtection")}</p>
              <p className="text-justify">{t("videoArea.description")}</p>{" "}
              {/** Add css hover effect + background */}
              <div className="single-success">
                <h5>{t("videoArea.client1ProfileTitle")}</h5>
                <p className="text-justify">
                  {t("videoArea.client1ProfileDescription")}
                </p>
              </div>
              <div className="single-success">
                <h5>
                  <Trans i18nKey="videoArea.client2ProfileTitle" />
                </h5>
                <p className="text-justify">
                  {t("videoArea.client2ProfileDescription")}
                </p>
              </div>
              <div className="single-success">
                <h5>
                  <Trans i18nKey="videoArea.client3ProfileTitle" />
                </h5>
                <p className="text-justify">
                  {t("videoArea.client3ProfileDescription")}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 video-right video-right-summary">
              <div className="right-bottom-area">
                <div className="author-lacture">
                  <p>{t("serviceArea2.description")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-inner">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="single-success">
                      <h5>{t("videoArea.client1ProfileTitle")}</h5>
                      <p>{t("videoArea.client1ProfileDescription")}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-success">
                      <h5>
                        <Trans i18nKey="videoArea.client2ProfileTitle" />
                      </h5>
                      <p>{t("videoArea.client2ProfileDescription")}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single-success">
                      <h5>
                        <Trans i18nKey="videoArea.client3ProfileTitle" />
                      </h5>
                      <p>{t("videoArea.client3ProfileDescription")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default VideoArea;
