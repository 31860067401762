import { Trans } from "react-i18next";
import "./styles.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils";

const FooterArea = () => {
  return (
    <footer className="footer-area" id="section-footer-area">
      <div className="footer_top section_gap_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12"></div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 mb-5">
                    <div className="single-footer-widget">
                      <h5 className="footer_title">
                        <Trans i18nKey="footerArea.authorization" />
                      </h5>
                      <p className="about-text">N° 10149504/0</p>
                    </div>
                  </div>
                  <div className="col-sm-12 mb-5">
                    <div className="single-footer-widget">
                      <h5 className="footer_title">
                        <Trans i18nKey="footerArea.rcs" />
                      </h5>
                      <p>RCS B274146</p>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="single-footer-widget">
                      <h5>
                        <Trans i18nKey="footerArea.vat" />
                      </h5>
                      <p>TVA LU34530069</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="single-footer-widget">
                <ul className="list">
                  <li>
                    <Link to={ROUTES.LEGAL}>
                      <Trans i18nKey="footerArea.legalNotice" />
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTES.POLICY}>
                      <Trans i18nKey="footerArea.privacyPolicy" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12"></div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <p>
                Copyright &copy; {new Date().getFullYear()} All rights reserved
              </p>
            </div>
            <div className="col-lg-6 col-md-12 text-right">
              <p>
                <Trans i18nKey="footerArea.doneBy" />
                <a href="https://opexify.io/" target="_blank">
                  Opexify
                </a>
              </p>
            </div>
            {/* <div className="col-lg-6 col-md-12 text-right">
            <div className="social-icons">
              <a href="#">
                <i className="fa fa-facebook"></i>
              </a>
              <a href="#">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fa fa-dribbble"></i>
              </a>
              <a href="#">
                <i className="fa fa-behance"></i>
              </a>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterArea;
