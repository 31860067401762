import { useTranslation } from "react-i18next";
import desolaLanre from "../../assets/images/desola-lanre-ologun.jpg";
import amrTaha from "../../assets/images/amr-taha.jpg";
import austinDistel from "../../assets/images/austin-distel.jpg";
import polinaKuzovkova from "../../assets/images/polina-kuzovkova.jpg";
import glenCarrie from "../../assets/images/glen-carrie.jpg";
import bacilaVlad from "../../assets/images/bacila-vlad.jpg";
import "./styles.css";

const ServiceArea = () => {
  const { t } = useTranslation();

  return (
    <section className="service-area section_gap" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="main_title">
              <h1>{t("serviceArea.title")}</h1>
              <p className="text-center">{t("serviceArea.introduction")}</p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-6" id="gdpr-support">
            <div className="single-service">
              <div className="service-thumb">
                <img
                  className="img-fluid"
                  src={desolaLanre}
                  alt={t("serviceArea.service1Title")}
                />
              </div>
              <div className="service-details">
                <h5>
                  <a href="#">{t("serviceArea.service1Title")}</a>
                </h5>
                <p className="text-justify">
                  {t("serviceArea.service1Description")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6" id="complianceAudits">
            <div className="single-service">
              <div className="service-thumb">
                <img
                  className="img-fluid"
                  src={amrTaha}
                  alt={t("serviceArea.service2Title")}
                />
              </div>
              <div className="service-details">
                <h5>
                  <a href="#">{t("serviceArea.service2Title")}</a>
                </h5>
                <p className="text-justify">
                  {t("serviceArea.service2Description")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6" id="awareness-training">
            <div className="single-service">
              <div className="service-thumb">
                <img
                  className="img-fluid"
                  src={austinDistel}
                  alt={t("serviceArea.service3Title")}
                />
              </div>
              <div className="service-details">
                <h5>
                  <a href="#">{t("serviceArea.service3Title")}</a>
                </h5>
                <p className="text-justify">
                  {t("serviceArea.service3Description")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6" id="privacyByDesign">
            <div className="single-service">
              <div className="service-thumb">
                <img
                  className="img-fluid"
                  src={polinaKuzovkova}
                  alt={t("serviceArea.service4Title")}
                />
              </div>
              <div className="service-details">
                <h5>
                  <a href="#">{t("serviceArea.service4Title")}</a>
                </h5>
                <p className="text-justify">
                  {t("serviceArea.service4Description")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6" id="data-protection">
            <div className="single-service">
              <div className="service-thumb">
                <img
                  className="img-fluid"
                  src={glenCarrie}
                  alt={t("serviceArea.service5Title")}
                />
              </div>
              <div className="service-details">
                <h5>
                  <a href="#">{t("serviceArea.service5Title")}</a>
                </h5>
                <p className="text-justify">
                  {t("serviceArea.service5Description")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6" id="DPOaaS">
            <div className="single-service">
              <div className="service-thumb">
                <img
                  className="img-fluid"
                  src={bacilaVlad}
                  alt={t("serviceArea.service6Title")}
                />
              </div>
              <div className="service-details">
                <h5>
                  <a href="#">{t("serviceArea.service6Title")}</a>
                </h5>
                <p className="text-justify">
                  {t("serviceArea.service6Description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceArea;
