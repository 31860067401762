import { Trans } from "react-i18next";
import "./styles.css";

const BannerArea = () => (
  <section className="home_banner_area overlay" id="section-banner">
    <div className="banner_inner">
      <div className="container">
        <div className="row fullscreen d-flex align-items-center justify-content-center">
          <div className="banner_content">
            <h2 className="banner-area-primary text-uppercase">
              esdatadvisory
            </h2>
            <p className="banner-title">
              <Trans i18nKey="homepage.bannerTitle" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BannerArea;
