import { Trans } from "react-i18next";
import edithSidjou from "../../assets/images/edithsidjouCut.jpeg";
import rogerTagotie from "../../assets/images/rogertafotieCut.png";
import "./styles.css";

const TeamArea = () => (
  <section className="section_gap team-area" id="team">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="main_title">
            <h2>
              <Trans i18nKey="teamArea.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="single_member">
            <div className="author">
              <img className="img-fluid" src={edithSidjou} alt="Edith Sijou" />
            </div>
          </div>
          <div className="profile-mobile mb-4">
            <div className="author_decs">
              <h5>Edith SIJOU</h5>
              <p className="profession">
                <Trans i18nKey="teamArea.edithJobTitle" />
              </p>
            </div>
            <Trans i18nKey="teamArea.edithProfile" />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="single_member">
            <div className="author">
              <img
                className="img-fluid"
                src={rogerTagotie}
                alt="Roger Tafotie"
              />
            </div>
          </div>
          <div className="profile-mobile">
            <div className="author_decs">
              <h5>Roger TAFOTIE</h5>
              <p className="profession">
                <Trans i18nKey="teamArea.rogerJobTitle" />
              </p>
            </div>
            <Trans i18nKey="teamArea.rogerProfile" />
          </div>
        </div>
      </div>
      <div className="row author-description-container">
        <div className="col-lg-6 col-md-6 col-sm-6 text-center">
          <div className="author_decs">
            <h5>Edith SIJOU</h5>
            <p className="profession">
              <Trans i18nKey="teamArea.edithJobTitle" />
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 text-center">
          <div className="author_decs">
            <h5>Roger TAFOTIE</h5>
            <p className="profession">
              <Trans i18nKey="teamArea.rogerJobTitle" />
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-6 profile-tablet text-justify">
          <Trans i18nKey="teamArea.edithProfile" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 profile-tablet text-justify">
          <Trans i18nKey="teamArea.rogerProfile" />
        </div>
      </div>
    </div>
  </section>
);

export default TeamArea;
