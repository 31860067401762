import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/en.json";
import frTranslations from "./locales/fr.json";
import { LANGUAGE } from "./utils";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: LANGUAGE.EN,
  lng: LANGUAGE.EN,
  resources: {
    en: {
      translation: enTranslations,
    },
    fr: {
      translation: frTranslations,
    },
  },
});

export default i18n;
