import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { animateScroll } from "react-scroll";
import AppHeader from "../../components/AppHeader";
import FooterArea from "../../components/FooterArea";
import "./styles.css";
import { SCROLL_DURATION } from "../../utils";

const p3ListKeys = ["p3ListItem1", "p3ListItem2", "p3ListItem3"];
const p7List1Keys = [
  "p7ListItem1",
  "p7ListItem2",
  "p7ListItem3",
  "p7ListItem4",
  "p7ListItem5",
];
const p7List2Keys = [
  "p7List2Item1",
  "p7List2Item2",
  "p7List2Item3",
  "p7List2Item4",
];

const Policypage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = `ESDATADVISORY - ${t("policypage.title")}`;
    animateScroll.scrollToTop({ duration: SCROLL_DURATION });
  }, [i18n.language]); // eslint-disable-line

  return (
    <div className="app policy-page">
      <AppHeader />
      <section className="section_gap">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="mb-5 text-center">{t("policypage.title")}</h1>
              <p className="lead">{t("policypage.p1")}</p>
              <h2 className="mb-3 mt-5 text-center">
                {t("policypage.p2Title")}
              </h2>
              <p className="lead">
                <Trans i18nKey="policypage.p2" />
              </p>
              <h2 className="mb-3 mt-5 text-center">
                {t("policypage.p3Title")}
              </h2>
              <p className="lead">
                <Trans i18nKey="policypage.p3" />
              </p>
              <ul className="mt-4 mb-4 lead">
                {p3ListKeys.map((key) => (
                  <li key={key}>
                    <Trans i18nKey={`policypage.${key}`} />
                  </li>
                ))}
              </ul>
              <p className="lead">
                <Trans i18nKey="policypage.p3text2" />
              </p>
              <h2 className="mb-3 mt-5 text-center">
                {t("policypage.p4Title")}
              </h2>
              <p className="lead">
                <Trans i18nKey="policypage.p4" />
              </p>
              <h2 className="mb-3 mt-5 text-center">
                {t("policypage.p5Title")}
              </h2>
              <p className="lead">
                <Trans i18nKey="policypage.p5" />
              </p>
              <h2 className="mb-3 mt-5 text-center">
                {t("policypage.p6Title")}
              </h2>
              <p className="lead">
                <Trans i18nKey="policypage.p6" />
              </p>
              <h2 className="mb-3 mt-5 text-center">
                {t("policypage.p7Title")}
              </h2>
              <p className="lead">
                <Trans i18nKey="policypage.p7" />
              </p>
              <ul className="mt-4 mb-4 lead">
                {p7List1Keys.map((key) => (
                  <li key={key}>
                    <Trans i18nKey={`policypage.${key}`} />
                  </li>
                ))}
              </ul>
              <p className="lead">
                <Trans i18nKey="policypage.p7text1" />
              </p>
              <ul className="mt-4 mb-4 lead">
                {p7List2Keys.map((key) => (
                  <li key={key}>
                    <Trans i18nKey={`policypage.${key}`} />
                  </li>
                ))}
              </ul>
              <p className="lead">
                <Trans i18nKey="policypage.p7text2" />
                <a href="mailto:info@esdatadvisory.com">
                  info@esdatadvisory.com
                </a>
                <br />
                <br />
                <Trans i18nKey="policypage.p7text3" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterArea />
    </div>
  );
};

export default Policypage;
