import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import "./styles.css";
import { SCROLL_DURATION } from "../../utils";

const CtaArea = () => {
  const { t } = useTranslation();

  return (
    <div className="cta-area section_gap overlay" id="cta-area-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <Link to="contact" smooth duration={SCROLL_DURATION}>
              {t("ctaArea.title")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaArea;
