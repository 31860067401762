import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./styles.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils";

const emailPattern =
  /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

const ContactArea = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [hasError, setHasError] = useState(false);
  const [isSentSuccess, setIsSentSuccess] = useState(false);

  const registerInput = (inputName, options = {}) => {
    return {
      ...register(inputName, { required: true, ...options }),
      ["aria-invalid"]: errors[inputName] ? "true" : "false",
    };
  };

  const submitForm = (data) => {
    let requestBody = "";

    Object.entries(data).forEach((entry, i) => {
      requestBody += `${entry[0]}=${entry[1]}`;

      if (Object.entries(data).length - 1 !== i) {
        requestBody += "&";
      }
    });

    fetch(process.env.PUBLIC_URL + "/contact_process.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          reset();
          setHasError(false);
          setIsSentSuccess(true);
        } else {
          setHasError(true);
        }
      })
      .catch(() => {
        setHasError(true);
      });
  };

  useEffect(() => {
    if (isSentSuccess) {
      setTimeout(() => {
        setIsSentSuccess(false);
      }, 5000);
    }
  }, [isSentSuccess]);

  return (
    <section className="contact_area section_gap" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="contact_info">
              <div className="info_item">
                <i className="lnr lnr-home"></i>
                <h6>Luxembourg, Luxembourg</h6>
                <p>3, Avenue du dix septembre, L-2551</p>
              </div>
              <div className="info_item">
                <i className="lnr lnr-phone-handset"></i>
                <h6>
                  <a href="#">00 (352) 26 262 447</a>
                </h6>
                <p>{t("contactArea.hour")}</p>
              </div>
              <div className="info_item">
                <i className="lnr lnr-envelope"></i>
                <h6>
                  <a href="mailto:info@esdatavidsory.com">
                    info@esdatavidsory.com
                  </a>
                </h6>
                <p>{t("contactArea.emailSubtitle")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            {hasError && (
              <div className="alert alert-danger text-center" role="alert">
                {t("contactArea.formError")}
              </div>
            )}
            {isSentSuccess && (
              <div className="alert alert-success text-center" role="alert">
                {t("contactArea.formSuccess")}
              </div>
            )}
            <form
              className="row contact_form"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder={t("contactArea.enterName")}
                    {...registerInput("name", { required: false })}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("contactArea.enterMail")}
                    {...registerInput("email", { pattern: emailPattern })}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder={t("contactArea.enterSubject")}
                    {...registerInput("subject")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder={t("contactArea.enterMessage")}
                    {...registerInput("message")}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="consent"
                    {...registerInput("giveConsent")}
                  />
                  <label className="form-check-label" htmlFor="consent">
                    {t("contactArea.checkGiveConsent")}{" "}
                    <Link to={ROUTES.POLICY} className="text-lowercase">
                      <Trans i18nKey="footerArea.privacyPolicy" />
                    </Link>
                    .
                  </label>
                </div>
              </div>
              <div className="col-md-12 text-right">
                <button
                  type="submit"
                  value="submit"
                  className="primary-btn text-uppercase"
                >
                  {t("contactArea.sendMessage")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactArea;
