import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import AppHeader from "../../components/AppHeader";
import FooterArea from "../../components/FooterArea";
import { ROUTES, SCROLL_DURATION } from "../../utils";

const Legalpage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = `ESDATADVISORY - ${t("legalpage.title")}`;
    animateScroll.scrollToTop({ duration: SCROLL_DURATION });
  }, [i18n.language]); // eslint-disable-line

  return (
    <div className="app">
      <AppHeader />
      <section className="section_gap">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="mb-5 text-center">{t("legalpage.title")}</h1>
              <p className="lead">{t("legalpage.p1")}</p>
              <h2 className="mb-3 mt-5">{t("legalpage.p2Title")}</h2>
              <p className="lead">{t("legalpage.p2")}</p>
              <h2 className="mb-3 mt-5">{t("legalpage.p3Title")}</h2>
              <h2 className="mb-3">{t("legalpage.p3SubTitle1")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p3" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p3SubTitle2")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p3text2" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p3SubTitle3")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p3text3" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p4Title")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p4" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p5Title")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p5" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p6Title")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p6" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p7Title")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p7" />
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p8Title")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p8" />
                <Link to={ROUTES.POLICY} className="text-lowercase">
                  <Trans i18nKey="footerArea.privacyPolicy" />
                </Link>
                .
              </p>
              <h2 className="mb-3 mt-5">{t("legalpage.p9Title")}</h2>
              <p className="lead">
                <Trans i18nKey="legalpage.p9" />
                <a href="mailto:info@esdatadvisory.com">
                  info@esdatadvisory.com
                </a>
                <br />
                <br />
                <Trans i18nKey="legalpage.p9text2" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterArea />
    </div>
  );
};

export default Legalpage;
