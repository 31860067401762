import { Trans } from "react-i18next";
import { Link } from "react-scroll";
import { SCROLL_DURATION } from "../../utils";
import "./styles.css";

const FeaturesArea = () => (
  <section className="features_area" id="features_counter">
    <div className="container">
      <div className="row counter_wrapper justify-content-center text-center">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single_feature single_feature_hover">
            <div className="thumb"></div>
            <div className="info-content">
              <p>
                <Link
                  to="privacyByDesign"
                  smooth
                  duration={SCROLL_DURATION}
                  offset={-250}
                  className="cursor-pointer"
                >
                  <Trans i18nKey="serviceArea.service4Title" />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="single_feature single_feature_hover text-center">
            <div className="thumb"></div>
            <div className="info-content">
              <p>
                <Link
                  to="complianceAudits"
                  smooth
                  duration={SCROLL_DURATION}
                  offset={-250}
                  className="cursor-pointer"
                >
                  <Trans i18nKey="serviceArea.service2Title" />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="single_feature single_feature_hover text-center">
            <div className="thumb"></div>
            <div className="info-content">
              <p>
                <Link
                  to="DPOaaS"
                  smooth
                  duration={SCROLL_DURATION}
                  offset={-250}
                  className="cursor-pointer"
                >
                  <Trans i18nKey="serviceArea.service6Title" />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="single_feature single_feature_hover text-center">
            <div className="thumb"></div>
            <div className="info-content">
              <p>
                <Link
                  to="gdpr-support"
                  smooth
                  duration={SCROLL_DURATION}
                  offset={-250}
                  className="cursor-pointer"
                >
                  <Trans i18nKey="serviceArea.service1Title" />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="single_feature single_feature_hover text-center">
            <div className="thumb"></div>
            <div className="info-content">
              <p>
                <Link
                  to="data-protection"
                  smooth
                  duration={SCROLL_DURATION}
                  offset={-250}
                  className="cursor-pointer"
                >
                  <Trans i18nKey="serviceArea.service5Title" />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="single_feature single_feature_hover text-center">
            <div className="thumb"></div>
            <div className="info-content">
              <p>
                <Link
                  to="awareness-training"
                  smooth
                  duration={SCROLL_DURATION}
                  offset={-250}
                  className="cursor-pointer"
                >
                  <Trans i18nKey="serviceArea.service3Title" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FeaturesArea;
