import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/Homepage";
import Legalpage from "./pages/Legalpage";
import { ROUTES } from "./utils";
import Policypage from "./pages/Policypage";
import ErrorBoundary from "./HOC/ErrorBoundary";
import Notfoundpage from "./pages/Notfoundpage";

const App = () => {
  const router = createBrowserRouter(
    [
      { index: ROUTES.HOMEPAGE, element: <Homepage /> },
      { path: ROUTES.LEGAL, element: <Legalpage /> },
      { path: ROUTES.POLICY, element: <Policypage /> },
      { path: "*", element: <Notfoundpage /> },
    ],
    { basename: "/" }
  );

  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
};

export default App;
