import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../App.css";
import AppHeader from "../../components/AppHeader";
import BannerArea from "../../components/BannerArea";
import ContactArea from "../../components/ContactArea";
import CtaArea from "../../components/CtaArea";
import FeaturesArea from "../../components/FeaturesArea";
import FooterArea from "../../components/FooterArea";
import ServiceArea from "../../components/ServiceArea";
import TeamArea from "../../components/TeamArea";
import VideoArea from "../../components/VideoArea";

const Homepage = () => {
  const { t, i18n } = useTranslation();

  const loadCustomScript = () => {
    document.querySelectorAll("script[src*='js/theme.js']").forEach((item) => {
      document.body.removeChild(item);
    });

    const customScript = document.createElement("script");

    customScript.setAttribute("src", "js/theme.js");
    document.body.appendChild(customScript);
  };

  useEffect(() => {
    document.title = `ESDATADVISORY - ${t("homepage.title")}`;
  }, [i18n.language]); // eslint-disable-line

  useEffect(() => {
    loadCustomScript();
  }, []);

  return (
    <div className="app">
      <AppHeader />
      <BannerArea />
      <FeaturesArea />
      <VideoArea />
      <ServiceArea />
      <TeamArea />
      <CtaArea />
      {/* <BlogArea /> */}
      <ContactArea />
      <FooterArea />
    </div>
  );
};

export default Homepage;
